/* .c-loader
-------------------------------------------------------------*/
$r: '.c-loader';

// block
//-----------------------------------------------------------
#{$r} {
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  padding: $size / 2;
  background-color: $white;
  animation-name: loaderShow;
  animation-duration: 0.6s;

  @keyframes loaderShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  // element
  //---------------------------------------------------------
  svg {
    width: 100%;
    height: 100%;
    max-width: $size * 12;
    max-height: $size * 12;
    transform-origin: center center;
    animation: rotate 2s linear infinite;
  }

  circle {
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-dasharray: 1, 200;
    animation: dash 1.3s ease-in-out infinite, loaderColor 5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, $size * 25;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: $size * 12, $size * 25;
      stroke-dashoffset: -$size * 4;
    }
    100% {
      stroke-dasharray: $size * 12, $size * 25;
      stroke-dashoffset: -$size * 16;
    }
  }

  @keyframes loaderColor {
    100%,
    0% {
      stroke: $black;
    }
  }

  // modifier
  //---------------------------------------------------------
  &--active {
    display: flex;
  }
}

// influence
//-----------------------------------------------------------
.c-button--has-loader #{$r} {
  display: flex;
  z-index: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);

  circle {
    animation: dash 1.3s ease-in-out infinite,
      loaderColorButton 5s ease-in-out infinite;
  }

  @keyframes loaderColorButton {
    100%,
    0% {
      stroke: $white;
    }
  }
}
